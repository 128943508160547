<template>
  <div class="tinymce">
    <editor
      id="tinymce"
      v-model="Html"
      :init="tinymceInit"
      @input="echoEditor"
    ></editor>
    <!-- <div v-html="Html"></div> -->
  </div>
</template>

<script>
import tinymce from "tinymce/tinymce";
import "tinymce/themes/silver";
import Editor from "@tinymce/tinymce-vue";
// import "tinymce/plugins/image"; //引入图片插件
import "tinymce/icons/default"; // 引入超链接插件
import "tinymce/plugins/link"; // 引入超链接插件
import "tinymce/plugins/code"; // 引入代码插件
import "tinymce/plugins/table"; // 引入表格插件
import "tinymce/plugins/lists"; // 序列插件
import "tinymce/plugins/wordcount"; // 字数插件
import "tinymce/plugins/paste";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/textcolor";

const base = window.__POWERED_BY_QIANKUN__
  ? process.env.VUE_APP_TINYMCE_URL
  : "";
export default {
  name: "Tinymce",

  components: { Editor },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      Html: "",
      tinymceInit: {
        language_url: base + "/static/tinymce/zh_CN.js",
        language: "zh_CN",
        skin_url: base + "/static/tinymce/skins/ui/oxide",
        plugins:
          "link lists code table colorpicker textcolor wordcount contextmenu",
        toolbar:
          "bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat",
        branding: false,

        height: 650, // 编辑器高度
        min_height: 400,
        // icons: 'custom',
        // inline: true,
        // statusbar: false,
        /* content_css: [ //可设置编辑区内容展示的css，谨慎使用
        '/static/reset.css',
        '/static/ax.css',
        '/static/css.css',
    ], */
        fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        importcss_append: true,
        toolbar_sticky: true,
        autosave_ask_before_unload: false,
      },
    };
  },
  watch: {
    value: {
      handler(val) {
        console.log(val);
        this.Html = val;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    console.log("tinymce实例", tinymce);
    //     let base = window.__POWERED_BY_QIANKUN__ ?'/market':''
    // window.tinymce.baseURL = base + '/static/tinymce4.7.5'
    tinymce.init({});
  },
  methods: {
    echoEditor() {
      this.$emit("input", this.Html);
    },
  },
};
</script>
